import React, { Component } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import COLORS from "../resources/colors/COLORS";
import SessionManager from "../Utils/SessionManager";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import "../css/Admin_css/admin-customize.css";
import myToast from "../Utils/MyToast";
import Iframe from "react-iframe";
import SelectClass from "../ChildComponent/SelectClass";

export default class Aadhaar extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: true,
      Loading: false,
      LoadClass: false,
      iFrameHeight: "0px",
      FILE_URL: "",
      FILE_NAME: "",
      FILE_DIRECTORY: "",
      DOCUMENT_TYPE: "",
      SESSION_ID: "",
      CLASS_ID: "",
      SECTION_ID: "",
      SELECTED_CLASS_POSITION: 0,
      mPrintableSamples: [],
      SELECTED_SAMPLE_ID: "",
      SELECTED_SAMPLE_POSITION: 0,
      CLASS_SECTION_NAME: "",
    };
    this.CallBackClassSelect = this.CallBackClassSelect.bind(this);
  }
  componentDidMount() {
   // alert("123");
    this.setState({
      LoadClass: true,
    })
  }
  CallBackClassSelect = (
    class_id,
    section_id,
    session_id,
    selected_position,
    class_name,
    section_name,
    session_name
  ) => {
    this.state.CLASS_ID = class_id;
    this.state.SECTION_ID = section_id;
    this.state.SESSION_ID = session_id;
    this.state.SELECTED_CLASS_POSITION = selected_position;
    alert("Back" + class_id + "/" + section_id + "/" + session_id);
    if (section_id == "No") {
      this.state.CLASS_SECTION_NAME = class_name;
    } else {
      this.state.CLASS_SECTION_NAME = class_name + ":" + section_name;
    }
    // this.StudentList();
  };
  StudentList() {
    this.setState({
      Loading: true,
    });
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getCurrentStudent.php?";
    URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;

    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          class_id: this.state.CLASS_ID,
          section_id: this.state.SECTION_ID,
          session_id: this.state.SESSION_ID,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        this.setState({
          Loading: false,
        });
        console.log("Response2>>>>>" + response);
        let api_status = response.api_status;
        alert(api_status);

        if (api_status == "OK") {

          console.log("response>>>>>" + this.state.StudentList);
        } else {
          let api_remark = response.api_remark;
          myToast.notify_warning(api_remark);
        }

        //console.log("response>>>>>"+api_status)
      })
      .catch((error) => {
        this.setState({
          Loading: false,
        });
        console.log("error>>>>>" + error);
      });
  }
  render() {
    if (this.state.loggedIn == false) {
      return <Redirect to="/Login" />;
    }
    if (this.state.Loading) {
      return <MyLoader />;
    } else {
      return (
        <Router>
          <switch>
            <Route
              path={"/AdminMain/Aadhaar"}
              exact
              render={() => {
                return (
                  <div
                    className="content"
                    style={{ background: COLORS.whitesmoke }}
                  >
                    <div
                      className="content-header"
                      style={{ background: COLORS.primary_color_shade6 }}
                    >
                      <div className="leftside-content-header">
                        <ul className="breadcrumbs">
                          <li>
                            <i className="fa fa-home" aria-hidden="true" />
                            <a href="#"> Aadhaar Number </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      className="animated fadeInUp bg_page_grey_rounded box_shadow"
                      style={{ "--mMargin": "50px", marginTop: "20px" }}>
                        {this.state.LoadClass===true?
                      <SelectClass
                        action={this.CallBackClassSelect}
                        selected_position="0"
                      ></SelectClass> : ''
                      }
                      {this.state.Loading ? <MyLoader /> :
                        <div className="bg_card_blue_outline btn_set">
                          <div className="row btn_set">
                            <div className="col-md-6 mp1_0 gravity_center" >
                              <div className="row">
                                <div className="col-md-3 gravity_center mp1_0">
                                  <div className=" bg_card_blue " style={{ height: "70px", width: "70px", }}>
                                    <div className="circular_image">
                                      <img src="" alt=""></img>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-9 " >
                                  <h6 className=" text-center "><span className="text_color">Student Name:</span> {"item.stu_name item.stu_name "} </h6>
                                  <h6 className="text-center "> <span className="text_color">Father Name:</span> {"item.stu_name"} </h6>
                                  <h6 className="text-center "> <span className="text_color">SRN Number:</span> {"item.stu_name"} </h6>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 ">
                              <div className='row mar_top'>
                                <div className="col-md-4 mp1_0 ">
                                  <label className="text_color mar_top2">Aadhaar Number</label>
                                </div>
                                <div className="col-md-6 padding_0">
                                  <div className="input-group ">
                                    <input id="" placeholder='Edit Aadhaar Number' type="text" value="" onChange={this.regChange} className="form-control" />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <center>
                                    <button className="btn btn-success" onClick={this.regSubmit} >Edit</button>
                                  </center>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                       <div className="row mar_top">
                        <button className="btn btn-success"
                          onClick={this.regSubmit} style={{ margin: '3% 0%', fontSize: '21px', width: '100%' }}>Submit All</button>
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          </switch>
        </Router>
      );
    }
  }
}
