import React, { Component } from 'react'
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import ExamList from '../cms/ExamList';
import '../css/Admin_css/admin-customize.css';
import icom_report_card from '../resources/icons/icons_report_card100_white.png'
import COLORS from '../resources/colors/COLORS';
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import myToast from '../Utils/MyToast';
import ReportViewClass from './ReportViewClass';
import StudentIdCard from '../cms/StudentIdCard';
import PrintableDocs from '../cms/PrintableDocs';
import PrintableDocsAdmitCard from '../cms/PrintableDocsAdmitCard';
import PrintableDocsBlank from '../cms/PrintableDocsBlank';
import PrintableDocsTopper from '../cms/PrintableDocsTopper';
import CompetitionAward from '../cms/CompetitionAward';
import PrintableDocsIdCard from '../cms/PrintableDocsIdCard';
export default class ReportManagement extends Component {
  constructor(props){
    super(props)
    const token=localStorage.getItem("token");
    let loggedIn=true;
    if(token==null){
      loggedIn=false;
    }
  
    this.state={
  
        loggedIn:true,
        Loading:false, 
        mReport:[],
        mPrintables:[],
        mReportAndPrint:[],
        
    }
  
  }
  componentDidMount()
  {
     window.scrollTo(0, 0);
     this.getReportMaster();
  }
  getReportMaster(){
  
  this.setState({
    Loading:true
  })
  const SCHOOL_CODE=SessionManager.getSchoolCode();
  var mStaffData=SessionManager.getStaffDetails();
  var STAFF_ID=mStaffData.staff_id;
  const FUNCTION_NAME='getReportMaster.php'
  URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
  var system_code=process.env.REACT_APP_SYSTEM_CODE;
  console.log("URL>>>>>"+URL)  
  axios.get(URL,{
    params:{staff_id:STAFF_ID,
      school_code:SCHOOL_CODE,
      system_code:system_code
    }
  }).then(res => res.data)
  .then((response) => {
    this.setState({ 
      Loading:false
     })
      //console.log("Response2>>>>>"+JSON.stringify(response));
      let api_status=response.api_status;
      // alert(api_status);
      
      if(api_status=="OK")
      {
      
         this.setState({
          
            mReportAndPrint:response.data
          })
          this.getList();
         
      }
      else{
        let api_remark=response.api_remark;
        myToast.notify_warning(api_remark);
      }
  
    //console.log("response>>>>>"+api_status)
   }).catch((error)=>{
    this.setState({ 
      Loading:false
     })
    console.log("error>>>>>"+error);
    });
  }
  getList()
  {
    for (let index = 0; index < this.state.mReportAndPrint.length; index++) {
      const element = this.state.mReportAndPrint[index];
      if(element.rpd_type=='REPORT')
      {
        this.state.mReport.push(element);
      }
      else if(element.rpd_type=='PRINTABLE')
      {
        this.state.mPrintables.push(element);
      }
      
    }
    //console.log("printable"+JSON.stringify(this.state.mPrintables));
    this.setState({

    })

  }
    render() {
      return (
      
        <Router>
          <switch>
            <Route path={'/AdminMain/ReportManagement'}  exact render={
              ()=>{
            return(
  
            <div className="content" style={{background:COLORS.white}}>
            <div className="content-header">
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li><i className="fa fa-home" aria-hidden="true" /><a href="#"> Report Management And Printables</a></li>
                </ul>
              </div>
            </div>
                <div className="animated fadeInUp bg_page_greylite_rounded box_shadow" style={{'--mMargin':'50px',marginTop:'20px'}}>
                 
                 <h5>Report List</h5>
               <div className="row bg_card_orange_outline">
            
                {this.state.mReport.map((item, index) => (

                    <div className="col-sm-4 col-md-4 " style={{padding:'10px'}}>
                    
                    <Link to={'/AdminMain/'+item.component_name+"/"+item.document_type}>
                    <div className="row bg_corner_round_grey">
                    <div className="col-sm-4" >
                      <div>
                            <img src={icom_report_card} className="bg_circle_card_orange" alt="student list image" style={{width:'50px',height:'50px',margin:'5px'}}/>
                        </div>
                    </div>
                    <div className="col-sm-8 gravity_center">
                          <h4 className="text-center" style={{color:COLORS.card_orange}}>{this.state.mReport[index].caption} </h4>
                    </div>
                    </div>
                    </Link>
                    </div>
                    
                         
               ))}

            </div>


               <h5>Printables List</h5>
               <div className="row bg_card_orange_outline">
            
                {this.state.mPrintables.map((item, index) => (
                    <div className="col-sm-4 col-md-4 " style={{padding:'10px'}}>
                    
                    <Link to={'/AdminMain/'+item.component_name+"/"+item.document_type}>
                    <div className="row bg_corner_round_grey">
                    <div className="col-sm-4" >
                      <div>
                            <img src={icom_report_card} className="bg_circle_card_orange" alt="student list image" style={{width:'50px',height:'50px',margin:'5px'}}/>
                        </div>
                    </div>
                    <div className="col-sm-8 gravity_center">
                          <h4 className="text-center" style={{color:COLORS.card_orange}}>{item.caption} </h4>
                    </div>
                    </div>
                    </Link>
                    </div>
                    
                         
               ))}

            </div>


  

                  </div>
                 </div>
    
              )
             }
           } />
  
      <Route path='/AdminMain/ReportViewClass/:document_type/'  component={ReportViewClass} />
      <Route path='/AdminMain/PrintableDocs/:document_type/'  component={PrintableDocs} />
      <Route path='/AdminMain/PrintableDocsAdmitCard/:document_type/'  component={PrintableDocsAdmitCard} />
      <Route path='/AdminMain/StudentIdCard'  component={StudentIdCard} />
      <Route path='/AdminMain/PrintableDocsBlank/:document_type/'  component={PrintableDocsBlank} />
      <Route path='/AdminMain/PrintableDocsTopper/:document_type/'  component={PrintableDocsTopper} />
      <Route path='/AdminMain/CompetitionAward/:document_type/'  component={CompetitionAward} />
      <Route path='/AdminMain/PrintableDocsIdCard/:document_type/'  component={PrintableDocsIdCard} />
     
  </switch>
  
  </Router>
    )
    }
}
