import React, { Component } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import COLORS from '../resources/colors/COLORS';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import Iframe from 'react-iframe'
import MdlSelectExamClassAndMultipleStudent from '../dialog/MdlSelectExamClassAndMultipleStudent';
import ExamReportCardRankList from './ExamReportCardRankList';
import ExamReportCardAttendanceReport from './ExamReportCardAttendanceReport';
import ExamMarkingSystem from './ExamMarkingSystem';
import ExamMarkingSystemAnnual from './ExamMarkingSystemAnnual';
import SchoolWorkingDays from './SchoolWorkingDays';
import ExamReportCardCrossList from './ExamReportCardCrossList';
export default class ExamStudentReportCard extends Component {
 
     
  constructor(props){
            super(props)
        
            const token=localStorage.getItem("token");
            let loggedIn=true;
            if(token==null){
              loggedIn=false;
            }
        
            this.state={
        
                loggedIn:true,
                Loading:false, 
                isOpenSelectStudent:false,
                showPreview:false,
                Total:0,
                mSelectedStudent:[],
                SELECTED_STUDENT_ID:'',
                mSamples:[],
                mSettings:[],
                mColorOption:[],
                iFrameHeight:'0px',
                SELECTED_SAMPLE_ID:'',
                SELECTED_SAMPLE_POSITION:0,
                SELECTED_COLOR_OPTION_POSITION:0,
                SELECTION_TYPE:'',
                FILE_URL:'',
                FILE_NAME:'',
                FILE_DIRECTORY:'',
                REPORT_CARD_ID:'',
                EXAM_ID:'',
                SESSION_ID:'',
                COLOR_OPTION_ID:'',
                REPORT_CARD_TYPE:'ANNUAL',
                MANUAL_ATTENDANCE:'',

            }
            this.HandleSelectedStudent = this.HandleSelectedStudent.bind(this);
            this.HandleCloseSelectStudent = this.HandleCloseSelectStudent.bind(this);
          
        }
        HandleCloseSelectStudent = () => {

          this.setState({
            isOpenSelectStudent:false
          });  
           
        }
      
        HandleSelectedStudent = () => {
          var mSelectedStudent=JSON.parse(localStorage.getItem("SelectedMultipleStudent"));
          if(mSelectedStudent==null){
            mSelectedStudent=[];
          }
          this.state.isOpenSelectStudent=false;
          this.state.showStudentIdCard=true;
          this.state.mSelectedStudent=mSelectedStudent;
          this.state.SELECTION_TYPE="student"; 
          this.state.SELECTED_STUDENT_ID="null";          
          this.setFileUrL();

        }
        SelectStudent=()=>{
         
          this.setState({
          
            isOpenSelectStudent:true,
          
          })
         
        }
        componentDidMount()
        {
         
          this.state.REPORT_CARD_ID=this.props.match.params.report_card_id;
          this.state.EXAM_ID=this.props.match.params.exam_id;
          this.state.SESSION_ID=this.props.match.params.session_id;
          this.state.SELECTED_STUDENT_ID=this.props.match.params.student_id;
         // alert(this.state.SELECTED_STUDENT_ID);
          this.getReportCardSettings();
          this.getPrintableSamples();
        }
        getReportCardSettings(){
          this.setState({
            Loading:true
          })
        
          const SCHOOL_CODE=SessionManager.getSchoolCode();
          const FUNCTION_NAME='getReportCardSettings.php?';
          URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
           //alert(URL);
         
          //console.log("URL>>>>>"+URL);

          axios.get(URL,{
            params:{
              school_code:SCHOOL_CODE,
              report_card_id:this.state.REPORT_CARD_ID,
              session_id:this.state.SESSION_ID,
              
            }
          }).then(res => res.data)
          .then((response) => {
           
            this.setState({ 
              Loading:false
             })
              //console.log("Response2>>>>>"+response);
              let api_status=response.api_status;
              //alert(JSON.stringify(response));
             
              if(api_status=="OK")
              {
              
                 this.setState({
                  mSettings:response.data,
                   
                  })
                  this.state.MANUAL_ATTENDANCE=this.state.mSettings[0]['manual_attendance'];
                  //alert( this.state.MANUAL_ATTENDANCE);
                  //alert("mSamples>>>>>"+JSON.stringify(this.state.mSamples));
                 
               
              }
              else{
                let api_remark=response.api_remark;
                myToast.notify_warning(api_remark);
              }
           }).catch((error)=>{
            this.setState({ 
              Loading:false
             })
            console.log("error>>>>>"+error);
            });
        
          }

          getPrintableSamples(){
          this.setState({
            Loading:true
          })
          var document_type="ANNUAL_REPORT_CARD";
          const SCHOOL_CODE=SessionManager.getSchoolCode();
          const FUNCTION_NAME='getPrintableSample.php?';
          URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
         // alert(URL);
          console.log("URL>>>>>"+URL);

          axios.get(URL,{
            params:{
              school_code:SCHOOL_CODE,
              document_type:document_type,
              
            }
          }).then(res => res.data)
          .then((response) => {
           
            this.setState({ 
              Loading:false
             })
              //console.log("Response2>>>>>"+response);
              let api_status=response.api_status;
              //alert(JSON.stringify(response));
             
              if(api_status=="OK")
              {
              
                 this.setState({
                    mSamples:response.data,
                   
                  })
                  //alert("mSamples>>>>>"+JSON.stringify(this.state.mSamples));
                  this.setSample(this.state.SELECTED_SAMPLE_POSITION);
               
              }
              else{
                let api_remark=response.api_remark;
                myToast.notify_warning(api_remark);
              }
           }).catch((error)=>{
            this.setState({ 
              Loading:false
             })
            console.log("error>>>>>"+error);
            });
        
          }
          setSample(selected_sample_position){
           
            var sample_id=this.state.mSamples[selected_sample_position].sample_id;
            this.state.FILE_NAME=this.state.mSamples[selected_sample_position].file_name;
            this.state.FILE_DIRECTORY=this.state.mSamples[selected_sample_position].file_directory;
            this.state.SELECTED_SAMPLE_ID=sample_id;

          //  alert(JSON.stringify(this.state.SELECTED_SAMPLE_ID));
            this.getColorOptions(selected_sample_position);
          
             
          }

  getColorOptions (selected_sample_position)
  {
    
      this.state.mColorOption=[];
      this.state.mColorOption=this.state.mSamples[selected_sample_position].color_options;
      //alert(JSON.stringify( this.state.mColorOption));
      this.setColorOptions(0);
    

  }  
  setColorOptions(selected_position_color_option)   
  {
    
    if(this.state.mColorOption.length==0)
    { 
      
        this.state.COLOR_OPTION_ID="";
    }
    else{
      
      this.state.COLOR_OPTION_ID=this.state.mColorOption[selected_position_color_option].color_option_id;
    }
   //alert(JSON.stringify( this.state.COLOR_OPTION_ID));
 
    if( this.state.SELECTED_STUDENT_ID!='null')
    {
       this.setFileUrL();
    }
    else{
      if(this.state.SELECTION_TYPE==='student')
      {
         this.setFileUrL();
               
     // alert(JSON.stringify(this.state.mSelectedStudent));
      }
    }

  }   
  setFileUrL(){
    var class_id="";
    var section_id="";
    var session_id="";
    const SCHOOL_CODE=SessionManager.getSchoolCode();
    var mStudentIds=[];
    if(this.state.SELECTED_STUDENT_ID!='null')
    {
      mStudentIds[0]=this.state.SELECTED_STUDENT_ID;
      session_id=this.state.SESSION_ID;
      this.state.SELECTION_TYPE='student';
    }
    else{
      if(this.state.SELECTION_TYPE==='student')
      {
  
          for (let index = 0; index < this.state.mSelectedStudent.length; index++) {
            const element = this.state.mSelectedStudent[index];
            var student_id=element.student_enrollment_no;
            session_id=element.session_id;
            mStudentIds[index]=student_id;
            
          }
     // alert(JSON.stringify(this.state.mSelectedStudent));
      }
      class_id=this.state.mSelectedStudent[0].class_id;
      section_id=this.state.mSelectedStudent[0].section_id;
    }

    
   
    this.state.FILE_URL=process.env.REACT_APP_SERVER_NAME+this.state.FILE_DIRECTORY+this.state.FILE_NAME+'?&student_ids='+JSON.stringify(mStudentIds)+'&class_id='+class_id+'&section_id='+section_id+'&session_id='+session_id+'&sample_id='+this.state.SELECTED_SAMPLE_ID+'&selection_type='+this.state.SELECTION_TYPE+'&report_card_id='+this.state.REPORT_CARD_ID+'&report_card_type='+this.state.REPORT_CARD_TYPE+'&color_option_id='+this.state.COLOR_OPTION_ID+'&system_code='+process.env.REACT_APP_SYSTEM_CODE+'&school_code='+SCHOOL_CODE;
    console.log("FILE_URL",this.state.FILE_URL);
    //alert(this.state.FILE_URL);
    this.setState({
      showPreview:true  
     });
     
    }   
    
    changeSample(position){
   
      this.setState({
        SELECTED_SAMPLE_POSITION:position
        }, () => {
         
          // alert(this.state.SELECTED_SAMPLE_POSITION);
           this.setSample(this.state.SELECTED_SAMPLE_POSITION);
      });
    
  
    }
    changeColorOption(position){
      
   
      this.setState({
        SELECTED_COLOR_OPTION_POSITION:position
        }, () => {
         
          // alert(this.state.SELECTED_SAMPLE_POSITION);
           this.setColorOptions(this.state.SELECTED_COLOR_OPTION_POSITION);
      });
    
  
    }
      
  
      render() {
            if( this.state.loggedIn==false)
            {
             return <Redirect to='/Login'/>;
            }
            if(this.state.Loading)
            {
               return <MyLoader/>
            }
            else{
              return (             
                <Router>
                <switch>
                  <Route path={'/AdminMain/ExamStudentReportCard/:report_card_id/:exam_id/:session_id/:student_id'}  exact render={
                    ()=>{
            return ( 
              
        
          <div className="content" style={{background:COLORS.white}}>
              <div className="content-header" style={{background:COLORS.primary_color_shade5}}>
                <div className="leftside-content-header">
                  <ul className="breadcrumbs">
                    <li><i className="fa fa-home" aria-hidden="true" style={{color:COLORS.white}} /><a href="#">Student Report Card </a></li>
                  </ul>
                </div>
              </div>  
              
          <div className="animated fadeInUpn bg_page_grey_rounded box_shadow" style={{'--mMargin':'20px'}}>
          
          <div className="row" style={{margin:'0px 10px 0px 10px'}} >
                <div className="col-sm-2 col-lg-2 padding_0"  onClick={this.HandleShow}>
                <Link to={"/AdminMain/ExamReportCardRankList/"+this.state.REPORT_CARD_ID+"/"+this.state.SESSION_ID}>
                  
                    <div className=" bg_card_primary_outline gravity_center_vertical" style={{padding:'5px',color:'#fff',margin:'0px 5px 0px 0px'}}>                 
                      <div className="bg_card_primary gravity_center width_match_parent margin_5 padding_5" >                        
                          <h5>View Rank List</h5>
                      </div>

                    </div>
                    </Link>
              </div>
              <div className="col-sm-2 col-lg-2 padding_0 height_match_parent"  onClick={this.HandleShow}>
              <Link to={"/AdminMain/ExamReportCardAttendanceReport/"+this.state.REPORT_CARD_ID+"/"+this.state.SESSION_ID+"/"+this.state.MANUAL_ATTENDANCE}>
               
                   <div className=" bg_card_primary_outline gravity_center_vertical" style={{padding:'5px',color:'#fff',margin:'0px 5px 0px 0px'}}>                 
                      

                      <div className="bg_card_primary gravity_center width_match_parent margin_5 padding_5" >
                          <h5>View Attendance</h5>
                      </div>
                    </div>

                    </Link>
              </div>
              <div className="col-sm-2 col-lg-2 padding_0 height_match_parent"  onClick={this.HandleShow}>
              <Link to={"/AdminMain/SchoolWorkingDays/"+this.state.REPORT_CARD_ID+"/"+this.state.SESSION_ID}>
              
                   <div className=" bg_card_primary_outline gravity_center_vertical" style={{padding:'5px',color:'#fff',margin:'0px 5px 0px 0px'}}>                 
                      

                      <div className="bg_card_primary gravity_center width_match_parent margin_5 padding_5" >
                          <h5 >Working Days</h5>
                      </div>
                    </div>
                    </Link>
              </div>
              <div className="col-sm-2 col-lg-2 padding_0 height_match_parent"  onClick={this.HandleShow}>
                  
              <Link to={"/AdminMain/ExamMarkingSystemAnnual/"+this.state.REPORT_CARD_ID+"/"+this.state.SESSION_ID}>
                 <div className=" bg_card_primary_outline gravity_center_vertical" style={{padding:'5px',color:'#fff',margin:'0px 5px 0px 0px'}}>                 
                      

                      <div className="bg_card_primary gravity_center width_match_parent margin_5 padding_5" onClick={this.SelectStudent}>
                          <h5>Marking System</h5>
                      </div>
                    </div>
                    </Link>
              </div>
              <div className="col-sm-2 col-lg-2 padding_0 height_match_parent"  onClick={this.HandleShow}>
                  
                  <Link to={"/AdminMain/ExamReportCardCrossList/"+this.state.REPORT_CARD_ID+"/"+this.state.SESSION_ID}>
                     <div className=" bg_card_primary_outline gravity_center_vertical" style={{padding:'5px',color:'#fff',margin:'0px 5px 0px 0px'}}>                 
                          
    
                          <div className="bg_card_primary gravity_center width_match_parent margin_5 padding_5" >
                              <h5>Cross List</h5>
                          </div>
                        </div>
                        </Link>
                  </div>
                  <div className="col-sm-2 col-lg-2 padding_0 height_match_parent"  onClick={this.HandleShow}>
                  
                  <Link to={"/AdminMain/ExamMarkingSystemAnnual/"+this.state.REPORT_CARD_ID+"/"+this.state.SESSION_ID}>
                     <div className=" bg_card_primary_outline gravity_center_vertical" style={{padding:'5px',color:'#fff',margin:'0px 5px 0px 0px'}}>                 
                          
    
                          <div className="bg_card_primary gravity_center width_match_parent margin_5 padding_5" >
                              <h5>Settings</h5>
                          </div>
                        </div>
                        </Link>
                  </div>

            </div>
            <div className="row">
                <div className="col-sm-6 col-lg-6 padding_0"  >
                    <div className="" style={{padding:'5px',color:'#000',margin:'0px 5px 0px 0px'}}>                 
                        <h5 className="">Sample List</h5>
                    </div>
              </div>
              <div className="col-sm-6 col-lg-6 padding_0 ">
                   
              </div>

            </div>

            <div className="row bg_card_grey_outline" style={{margin:'0px 10px 0px 10px',height:'140px'}}>
                
            {this.state.mSamples && this.state.mSamples.map((item, index) => (

              <div className="col-sm-2 col-lg-2 padding_0 height_match_parent" >
                    <div className={this.state.SELECTED_SAMPLE_POSITION==index? 'bg_card_green_outline cursor':'bg_card_grey_outline cursor'} style={{padding:'5px',color:'#fff',margin:'5px 10px 0px 10px',height:'110px'}} onClick={e=>this.changeSample(index)}>                 
                      <div className="gravity_center">
                          <img src={item.sample_img} class="img_logo" />
                      </div>
                    
                    </div>
                    <div className="gravity_center">
                      {item.sample_name}
                    </div>                   
                     
              </div>
            ))}

            </div>


            <div className="row">
                <div className="col-sm-6 col-lg-6 padding_0"  >
                    <div className="" style={{padding:'5px',color:'#000',margin:'0px 5px 0px 0px'}}>                 
                        <h5 className="">Color Options</h5>
                    </div>
              </div>
              <div className="col-sm-6 col-lg-6 padding_0 ">
                   
              </div>

            </div>

            <div className="row bg_card_grey_outline" style={{margin:'0px 10px 0px 10px',height:'80px'}}>
                
            {this.state.mColorOption && this.state.mColorOption.map((item, index) => {

              var color_code=item.sample_color_code;
             // alert(color_code);
              var arrColor = color_code.split("_");
            

              return(

              <div className="col-sm-2 col-lg-2 padding_0 height_match_parent" >
                    <div className={this.state.SELECTED_COLOR_OPTION_POSITION==index? 'bg_card_green_outline cursor':'bg_card_grey_outline cursor'} style={{padding:'5px',color:'#fff',margin:'5px 10px 0px 10px',height:'40px'}} onClick={e=>this.changeColorOption(index)}>                 
                      <div className="row gravity_center height_match_parent" >
                        <div className="col-sm-6 col-lg-6" style={{'backgroundColor':arrColor[0],height:'100%'}}>
                        </div>

                        <div className="col-sm-6 col-lg-6" style={{backgroundColor:arrColor[1],height:'100%'}}>
                        </div>
                      </div>
                    
                    </div>
                    <div className="gravity_center">
                      {item.sample_color_name}
                    </div>                   
                     
              </div>
              );
              })}

            </div>

            <div className="row" style={{margin:'0px 10px 0px 10px'}} >
                <div className="col-sm-6 col-lg-6 padding_0"  onClick={this.HandleShow}>
                    <div className=" bg_card_primary_outline gravity_center_vertical" style={{padding:'5px',color:'#fff',margin:'0px 5px 0px 0px'}}>                 
                      <div className="bg_card_primary gravity_center width_match_parent margin_5 padding_5" onClick={this.SelectStudent}>                        
                          <h5 className="arrow"> Select Students Manually</h5>
                      </div>

                    </div>
              </div>
              <div className="col-sm-6 col-lg-6 padding_0 height_match_parent"  onClick={this.HandleShow}>
                   <div className=" bg_card_primary_outline gravity_center_vertical" style={{padding:'5px',color:'#fff',margin:'0px 5px 0px 0px'}}>                 
                      

                      <div className="bg_card_primary gravity_center width_match_parent margin_5 padding_5" onClick={this.SelectStudent}>
                          <h5 className="arrow"> Select Class</h5>
                      </div>
                    </div>
              </div>

            </div>

            <div className="row" style={{margin:'0px 10px 0px 10px'}}>
                <h5 className="">Preview</h5>
            </div>

            
            <div className="row"  style={{margin:'0px 10px 0px 10px'}}> 
              {this.state.showPreview ? <div><MyLoader/></div> 
                      
                      :''}                   
                <Iframe url={this.state.FILE_URL}
                      
                        width="100%"
                        height="2500px"
                        overflow="visible"
                        id="iFrameStudentData"
                        className="bg_card_primary_outline"
                        display="initial"
                        position="relative"                      
                        frameBorder="0"
                      
                        onLoad={() => {
                        // alert("loaded");
                         //this.autoResize();
                    
                          this.setState({
                            showPreview:false,
                            
                          })
                      }} 
                      />
              
              </div> 

          </div>
          {this.state.isOpenSelectStudent===true? <MdlSelectExamClassAndMultipleStudent exam_id={this.state.EXAM_ID} session_id={this.state.SESSION_ID} action={this.HandleCloseSelectStudent} action2={this.HandleSelectedStudent}></MdlSelectExamClassAndMultipleStudent>:''}

               
         </div>
  
      )


     }
          } />
      <Route path='/AdminMain/ExamReportCardRankList/:report_card_id/:session_id'  component={ExamReportCardRankList} />
      <Route path='/AdminMain/ExamReportCardAttendanceReport/:report_card_id/:session_id/:manual_attendance/'  component={ExamReportCardAttendanceReport} />
      <Route path='/AdminMain/ExamMarkingSystemAnnual/:report_card_id/:session_id'  component={ExamMarkingSystemAnnual} />
      <Route path='/AdminMain/SchoolWorkingDays/:report_card_id/:session_id'  component={SchoolWorkingDays} />
      <Route path='/AdminMain/ExamReportCardCrossList/:report_card_id/:session_id'  component={ExamReportCardCrossList} />
   
                 
    </switch>       
   </Router>  
    )
  }
 }
 }