import React, { Component,createRef } from 'react'
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import {BrowserRouter as Router,Link, Redirect, Route, } from 'react-router-dom';
import '../css/Admin_css/admin-customize.css';
import myToast from '../Utils/MyToast';
import COLORS from '../resources/colors/COLORS';
import Table from 'react-bootstrap/Table'
export default class ExamDateSheet extends Component {
    constructor(props){
        super(props)

        this.slt_shift=createRef();
        this.slt_subject=createRef();
        this.slt_subject_paper=createRef();
      const token=localStorage.getItem("token");
      let loggedIn=true;
      if(token==null){
        loggedIn=false;
      }
    
      this.state={
    
          loggedIn:true,
          Loading:false, 
          mClassList:[], 
          mDateSheet:[], 
          mSubject:[], 
          mSubjectPaper:[], 
          EXAM_ID:'',
          SESSION_ID:'',
          EXAM_NAME:'',
          CLASS_ID:'',
          SECTION_ID:'',
          EXAM_STATUS:'',
          exam_shift:'',
          subject_id:'',
          subject_paper_id:'',
          selected_subject:'',
          selected_subject_paper:'',

          CLASS_SELECTED_POSITION:0,
          LoadingDateSheet:false,
          input_exam_date:"",
          input_exam_time:"",
          DATE_SHEET_ID:'',
        
      }
      this.onEdtChange = this.onEdtChange.bind(this);

    }
    onEdtChange(e) {

      this.setState({
        [e.target.id]: e.target.value
      })
    }

    OnShiftChange = (event) => { 
      
     
    };
    OnSubjectChange = (event) => { 
     
     this.getSubject( this.slt_subject.current.selectedOptions[0].getAttribute('position_no'));
     
    };
    componentDidMount()
    {
      this.state.EXAM_ID=this.props.match.params.exam_id;
      this.state.SESSION_ID=this.props.match.params.session_id;  
      this.state.EXAM_NAME=this.props.match.params.exam_name;
      this.state.EXAM_STATUS=this.props.match.params.exam_status;
      this.getExamParticipatingClass();
     
    }
    
    getExamParticipatingClass(){
      this.setState({
        Loading:true
      })
      
      const SCHOOL_CODE=SessionManager.getSchoolCode();
      const FUNCTION_NAME='getClassWithSubjectsPartAndPaper.php?';
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
     
     // console.log("URL>>>>>"+URL);

      axios.get(URL,{
          params:{school_code:SCHOOL_CODE,
          exam_id:this.state.EXAM_ID,
          session_id:this.state.SESSION_ID,
          
        }
      }).then(res => res.data)
      .then((response) => {
       
        this.setState({ 
          Loading:false
         })
         // console.log("Response2>>>>>"+response);
          let api_status=response.api_status;
          //alert(JSON.stringify(response));
         
          if(api_status=="OK")
          {
            this.state.mClassList=response.data;
            this.setClassList(this.state.CLASS_SELECTED_POSITION);
           
          }
          else{
            let api_remark=response.api_remark;
            myToast.notify_warning(api_remark);
          }
       }).catch((error)=>{
        this.setState({ 
          Loading:false
         })
        //console.log("error>>>>>"+error);
        });
    
      }
      selectClass=(position)=>{  
      this.setClassList(position);
      
    }
    setClassList(selected_position){      
        if(this.state.mClassList.length!==0)
        {
            this.state.CLASS_ID=this.state.mClassList[selected_position].class_id;
            this.state.SECTION_ID=this.state.mClassList[selected_position].section_id;

            if(this.state.SECTION_ID=="null")
            {
                this.state.SECTION_ID="No";
            }
            this.state.CLASS_SELECTED_POSITION=selected_position;

        }
        this.getExamSheet();
    
        this.getSubject(0);
      }
       
    getExamSheet(){
      this.setState({
        LoadingDateSheet:true
      })
      
      const SCHOOL_CODE=SessionManager.getSchoolCode();
      const FUNCTION_NAME='getExamDateSheet.php?';
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
     
     // console.log("URL>>>>>"+URL);

      axios.get(URL,{
          params:{school_code:SCHOOL_CODE,
          exam_id:this.state.EXAM_ID,
          session_id:this.state.SESSION_ID,
          class_id:this.state.CLASS_ID,
          section_id:this.state.SECTION_ID,
          
        }
      }).then(res => res.data)
      .then((response) => {
       
        this.setState({ 
          LoadingDateSheet:false
         })
         // console.log("Response2>>>>>"+response);
          let api_status=response.api_status;
          //alert(JSON.stringify(response));
         
          if(api_status==="OK")
          {
            
            this.setState({              
              mDateSheet:response.data
            })
           
          }
          else{
            this.setState({              
              mDateSheet:[]
            })
            let api_remark=response.api_remark;
           // myToast.notify_warning(api_remark);
          }
       }).catch((error)=>{
        this.setState({ 
          Loading:false
         })
        //console.log("error>>>>>"+error);
        });
    
      }


      selectClass=(position)=>{  
      this.setClassList(position);
      
    }
    getSubject(selected_position_subject){
      
    
      this.state.mSubject=[];
      this.state.mSubject=this.state.mClassList[this.state.CLASS_SELECTED_POSITION].subject;
      if(this.state.mSubject!==null && this.state.mSubject.length!==0)
      {
        var selected_subject_id=this.state.mSubject[selected_position_subject].subject_id;  
        this.state.selected_subject=this.state.mSubject[selected_position_subject].subject_name;    
        this.getSubjectPaper(selected_subject_id);
      }  
       
    }
    getSubjectPaper(selected_subject_id){
    
      this.state.mSubjectPaper=[];
      var mAllSubjectPaper=this.state.mClassList[this.state.CLASS_SELECTED_POSITION].subject_paper;
      //alert(JSON.stringify(mAllSubjectPaper));
      if(mAllSubjectPaper!==null && mAllSubjectPaper.length!==0)
      {
        //alert(JSON.stringify(mAllSubjectPaper));

        for (let index = 0; index < mAllSubjectPaper.length; index++) {
          const element = mAllSubjectPaper[index];
          if(element.subject_id===selected_subject_id)
          {
            this.state.mSubjectPaper.push(element);
          }
          
        }
      }
      
      this.setState({

      })
      
      
    }
    submitDateSheet(){

      if(this.isValidInput())
      {
        this.AddExamDateSheet();
      }
      
      
    }
    AddExamDateSheet(){
      this.setState({
        LoadingDateSheet:true
      })
      
      const SCHOOL_CODE=SessionManager.getSchoolCode();
      const FUNCTION_NAME='AddExamDateSheet.php?';
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
      this.state.exam_shift=this.slt_shift.current.selectedOptions[0].getAttribute('shift');
      this.state.subject_id=this.slt_subject.current.selectedOptions[0].getAttribute('subject_id');
      this.state.subject_paper_id=this.slt_subject_paper.current.selectedOptions[0].getAttribute('exam_paper_id');
     
      //console.log("URL>>>>>"+URL);
    
     
      axios.get(URL,{
        params:{session_id:this.state.SESSION_ID,
          exam_id:this.state.EXAM_ID,
          class_id:this.state.CLASS_ID,
          section_id:this.state.SECTION_ID,
          subject_id:this.state.subject_id,
          subject_paper_id:this.state.subject_paper_id,
          exam_date:this.state.input_exam_date,
          exam_time:this.state.input_exam_time,
          exam_shift:this.state.exam_shift,
          school_code:SCHOOL_CODE,         
        }
      }).then(res => res.data)
      .then((response) => {
       
        this.setState({ 
          LoadingDateSheet:false
         })
         // console.log("Response2>>>>>"+response);
          let api_status=response.api_status;
          //alert(JSON.stringify(response));
         
          if(api_status==="OK")
          {
            
            myToast.notify_success("Exam DateSheet Added Successfully");
           // this.props.history.push('/AdminMain/ExamDateSheet/'+this.state.EXAM_ID+"/"+this.state.SESSION_ID+"/"+this.state.EXAM_NAME+"/"+this.state.START_DATE);
           this.getExamSheet();
          }
          else{
            let api_remark=response.api_remark;
           // myToast.notify_warning(api_remark);
          }
       }).catch((error)=>{
        this.setState({ 
          Loading:false
         })
        //console.log("error>>>>>"+error);
        });
    
      }
    isValidInput(){
     
      if(!this.state.input_exam_date)
      {
          
        alert("Enter Exam Date!!!");
        return false;
      }
      else if(!this.state.input_exam_time)
      {
          
        alert("Enter Exam Time!!!");
        return false;
      }
     
      
    
      return true
    }
    deleteDateSheet=(item)=>{
      //alert(JSON.stringify(item2));
      this.state.DATE_SHEET_ID=item.datesheet_id;
      this.deleteExamDateSheet();

     
    }
    deleteExamDateSheet(){
      this.setState({
        LoadingDateSheet:true
      })
      
      const SCHOOL_CODE=SessionManager.getSchoolCode();
      var mStaffData=SessionManager.getStaffDetails();
      var STAFF_ID=mStaffData.staff_id;
      const FUNCTION_NAME='deleteExamDateSheet.php?';
      URL=process.env.REACT_APP_SERVER_NAME+process.env.REACT_APP_TARGET_DIR+FUNCTION_NAME;
     
     // console.log("URL>>>>>"+URL);

      axios.get(URL,{
          params:{school_code:SCHOOL_CODE,
          exam_id:this.state.EXAM_ID,
          datesheet_id:this.state.DATE_SHEET_ID,
          staff_id:STAFF_ID,
          
        }
      }).then(res => res.data)
      .then((response) => {
       
        this.setState({ 
          LoadingDateSheet:false
         })
         //console.log("Response2>>>>>"+response);
          let api_status=response.api_status;
          //alert(JSON.stringify(response));
         
          if(api_status==="OK")
          {
            myToast.notify_success("Exam DateSheet Deleted Successfully");
            this.getExamSheet();
           
          }
          else{
            this.setState({              
             
            })
            let api_remark=response.api_remark;
            myToast.notify_warning(api_remark);
          }
       }).catch((error)=>{
        this.setState({ 
          Loading:false
         })
        //console.log("error>>>>>"+error);
        });
    
      }
    

      render() {
        if( this.state.loggedIn===false)
        {
         return <Redirect to='/Login'/>;
        }
        if(this.state.Loading)
        {
           return <MyLoader/>
        }else{

            return (             
                <Router>
                <switch>
                  <Route path={'/AdminMain/ExamDateSheet/:exam_id/:session_id/:exam_name'}  exact render={
                    ()=>{
                      
                 return (
                <div className="content">
                    <div className="content-header">
                            <div className="leftside-content-header">
                            <ul className="breadcrumbs">
                                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">{"Exam Date Sheet( "+this.state.EXAM_NAME+" )"}</a></li>
                            </ul>
                            </div>
                    </div>  
                    <div className="animated fadeInUpn bg_page_grey_rounded box_shadow" style={{'--mMargin':'50px'}}>     
                    <div style={{padding:'0px 15px 0px 15px'}}><h5>Participating Class</h5></div>
                    <div className="row bg_card_primary" style={{padding:'5px',margin:'0px 15px 0px 15px'}}>
                    {this.state.mClassList.map((item, index) => (
                    <div className="col-sm-1 col-md-1" style={{padding:'0px 2px 5px 2px'}}>
                        <div className={this.state.CLASS_SELECTED_POSITION===index?"bg_class_select gravity_center":"bg_class_deselect gravity_center"} onClick={e=>this.selectClass(index)} style={{cursor:'pointer'}}> 
                           <p style={{fontSize:14}}> {item.section_id==='No'?item.class_name:item.class_name+':'+item.section_name} </p>
                        </div>
                    </div>
                    ))}
                      </div>

                       <div style={{padding:'0px 15px 0px 15px'}}><h5>Add New Date Sheet</h5></div>

                       <div className="row bg_card_primary_outline" style={{padding:'10px',margin:'0px 15px 0px 15px'}}>
                      
                         <div className="col-sm-2 col-md-2 bg_grid" style={{padding:'0px 2px 2px 2px'}}>
                         <h5>Exam Date:</h5>
                              <input type="text" id="input_exam_date" value={this.state.input_exam_date} onChange={this.onEdtChange}style={{ width:'100%',height:'40px',fontSize:'18px'}} />
                        </div>

                            <div className="col-sm-2 col-md-2 bg_grid" style={{padding:'0px 2px 0px 2px'}}>
                            <h5>Exam Time:</h5>
                              <input type="text" id="input_exam_time" value={this.state.input_exam_time} onChange={this.onEdtChange}style={{ width:'100%',height:'40px',fontSize:'18px'}} />
                           </div>

                            <div className="col-sm-2 col-md-2 bg_grid" style={{padding:'0px 2px 0px 2px'}}>
                            <h5>Exam Shift:</h5>
                            <select  onChange={this.OnShiftChange} ref ={this.slt_shift} style={{ width:'100%',height:'40px',fontSize:'18px'}}>
                                <option shift="First">First</option>
                                <option shift="Second">Second</option>
                             </select>
                           </div>
                           
                           <div className="col-sm-2 col-md-2 bg_grid" style={{padding:'0px 2px 0px 2px'}}>
                            <h5>Subject:</h5>
                            <select  onChange={this.OnSubjectChange} ref ={this.slt_subject} style={{ width:'100%',height:'40px',fontSize:'18px'}}>
                                      {this.state.mSubject && this.state.mSubject.map((item, index) => ( 
                                      <option subject_id={item.subject_id} position_no={index} value={null} > {item.subject_name} </option>
                  
                                      ))}
                            </select>
                           
                           </div>
                           
                           <div className="col-sm-2 col-md-2 bg_grid" style={{padding:'0px 2px 0px 2px'}}>
                            <h5>Subject Paper:</h5>
                            <select  onChange={this.OnSubjectPagerChange} ref ={this.slt_subject_paper} style={{ width:'100%',height:'40px',fontSize:'18px'}}>
                                      {this.state.mSubjectPaper && this.state.mSubjectPaper.map((item, index) => ( 
                                      <option exam_paper_id={item.exam_paper_id} position_no={index} value={null}> {item.exam_paper_name} </option>
                  
                                      ))}
                            </select>
                           </div>
                           <div className="col-sm-2 col-md-2 bg_grid gravity_center" style={{padding:'0px 2px 0px 2px'}}>
                           <button className="btn btn-success" onClick={()=>this.submitDateSheet()} style={{fontSize:'16px', margin:'5px',width:'80px',height:'30px',background:COLORS.primary_color_shade5}}> Add </button>
                                    
                           </div>
                       </div>

                      {this.state.LoadingDateSheet?<div><MyLoader/></div>:
                        <>
                       <div style={{padding:'0px 15px 0px 15px'}}><h5>Date Sheet Preview</h5></div>

                       <div className="row bg_card_primary_outline" style={{padding:'5px',margin:'0px 15px 0px 15px'}}>
                      
                        <Table striped bordered hover style={{width:'100%',border:'3px'}}>
                          <tr>
                            <th style={{textAlign: 'center', verticalAlign: 'middle',fontSize:14}}>Sr.</th>
                            <th style={{textAlign: 'center', verticalAlign: 'middle',fontSize:14}}>Examination Date</th> 
                            <th style={{textAlign: 'center', verticalAlign: 'middle',fontSize:14}}>Timing</th>
                            <th style={{textAlign: 'center', verticalAlign: 'middle',fontSize:14}}>Shift</th>
                            <th style={{textAlign: 'center', verticalAlign: 'middle',fontSize:14}}>Subject</th>
                            <th style={{textAlign: 'center', verticalAlign: 'middle',fontSize:14}}>Subject Paper</th>
                            <th style={{textAlign: 'center', verticalAlign: 'middle',fontSize:14}}>Action</th>
                          </tr>
                           {this.state.mDateSheet && this.state.mDateSheet.map((item2, index2) => (
                                <tr>
                                  <td style={{textAlign: 'center', verticalAlign: 'middle',fontSize:16}}>{  (index2+1)}</td>
                                  <td style={{textAlign: 'center', verticalAlign: 'middle',fontSize:16}}>{this.state.mDateSheet[index2].exam_date}</td> 
                                  <td style={{textAlign: 'center', verticalAlign: 'middle',fontSize:16}}>{this.state.mDateSheet[index2].exam_time}</td>
                                  <td style={{textAlign: 'center', verticalAlign: 'middle',fontSize:16}}>{this.state.mDateSheet[index2].exam_shift}</td>
                                  <td style={{textAlign: 'center', verticalAlign: 'middle',fontSize:16}}>{this.state.mDateSheet[index2].subject_name}</td>
                                  <td style={{textAlign: 'center', verticalAlign: 'middle',fontSize:16}}>{this.state.mDateSheet[index2].exam_paper_name}</td>
                                  <td className='gravity_center'>
                                    <button className="bg_card_primary btn btn-success gravity_center" style={{height:'24px'}} onClick={()=>this.deleteDateSheet(item2)}>Delete</button>
                                    </td>
                                </tr>
                        
                            ))}
                          
                        </Table>
                      
                       </div>
                          </>
                    }
              

                    </div> 
                    
                   
            
               </div>
  
            )
          }
          } />
           
           
                 
    </switch>       
   </Router>  
    )
  }
 }
 }