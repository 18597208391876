import React, { useState, useEffect } from 'react';
import '../css/Admin_css/admin-customize.css';
import axios from 'axios';
import MyLoader from '../Spinner/MyLoader';
import SessionManager from '../Utils/SessionManager';
import myToast from '../Utils/MyToast';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import AdvancedPayment from './AdvancedPayment';
import COLORS from '../resources/colors/COLORS';

const ExamAddMarkSubjectWise = (props) => {
  const [loading, setLoading] = useState(false);
  const [classList, setClassList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [selectedClassPosition, setSelectedClassPosition] = useState(-1);
  const [selectedSubjectPosition, setSelectedSubjectPosition] = useState(0);

  const { exam_id: EXAM_ID, session_id: SESSION_ID, exam_name: EXAM_NAME } = props.match.params;

  useEffect(() => {
    getExamParticipatingClass();
  }, []);

  useEffect(() => {
    if (selectedClassPosition >= 0) {
      getClassList(selectedClassPosition);
    }
  }, [selectedClassPosition]);

  useEffect(() => {
    if (subjectList.length !== 0 && selectedClassPosition >= 0) {
      const selectedSubject = subjectList[selectedSubjectPosition];
      const CLASS_ID = classList[selectedClassPosition].class_id;
      const SECTION_ID = classList[selectedClassPosition].section_id === 'null' ? 'No' : classList[selectedClassPosition].section_id;
      //alert("CLASS_ID"+CLASS_ID);
      //alert("SECTION_ID"+SECTION_ID);
      
      if (selectedSubject) {
        getClassStudentMarks(CLASS_ID, SECTION_ID, selectedSubject.subject_id);
      }
    }
  }, [selectedSubjectPosition, subjectList]);

  const getExamParticipatingClass = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = 'getClassWithSubjectsPartAndPaper.php';
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    axios.get(URL, {
      params: {
        school_code: SCHOOL_CODE,
        exam_id: EXAM_ID,
        session_id: SESSION_ID,
      }
    })
      .then(res => res.data)
      .then((response) => {
        setLoading(false);
        if (response.api_status === "OK") {
          setClassList(response.data);
          setSelectedClassPosition(-1);
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        myToast.notify_error("Error fetching class data");
      });
  };

  const getClassList = (selected_position) => {
    if (classList.length !== 0 && selected_position >= 0) {
      const selectedClass = classList[selected_position];
      setSubjectList(selectedClass.subject);
      setSelectedSubjectPosition(0);
    }
  };

  const getClassStudentMarks = (CLASS_ID, SECTION_ID, SUBJECT_ID) => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = 'getClassSubjectMarks.php';
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;
   // alert(CLASS_ID+SECTION_ID+SUBJECT_ID+EXAM_ID+SESSION_ID);
    axios.get(URL, {
      params: {
        school_code: SCHOOL_CODE,
        exam_id: EXAM_ID,
        class_id: CLASS_ID,
        section_id: SECTION_ID,
        subject_id: SUBJECT_ID,
        session_id: SESSION_ID,
      }
    })
      .then(res => res.data)
      .then((response) => {
        setLoading(false);
        alert(JSON.stringify(response));
        if (response.api_status === "OK") {
          // Process data if needed
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        myToast.notify_error("Error fetching student marks");
      });
  };

  const token = localStorage.getItem("token");
  if (!token) {
    return <Redirect to='/Login' />;
  }

  if (loading) {
    return <MyLoader />;
  }

  return (
    <Router>
      <Route exact path='/AdminMain/ExamAddMarkSubjectWise/:exam_id/:session_id/:exam_name/'>
        <div className="content">
          <div className="content-header">
            <div className="leftside-content-header">
              <ul className="breadcrumbs">
                <li><i className="fa fa-home" aria-hidden="true" /><a href="#">Add Mark Subject Wise</a></li>
              </ul>
            </div>
          </div>

          <div className="animated fadeInUp bg_page_white_rounded box_shadow" style={{ '--mMargin': '80px' }}>
            <div className="row bg_card_primary" style={{ padding: '5px', margin: '0px 15px 0px 15px' }}>
              {classList.map((item, index) => (
                <div className="col-sm-1 col-md-1" style={{ padding: '0px 2px 5px 2px' }} key={index}>
                  <div
                    className={selectedClassPosition === index ? "bg_class_select gravity_center" : "bg_class_deselect gravity_center"}
                    onClick={() => setSelectedClassPosition(index)}
                    style={{ cursor: 'pointer' }}>
                    <p style={{ fontSize: 14 }}>{item.section_id === 'No' ? item.class_name : `${item.class_name}:${item.section_name}`}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="bg_card_blue_outline" style={{ margin: '5px 15px 5px 15px' }}>
              <div style={{ padding: '5px' }}>
                <h5>Participating Subject</h5>
              </div>
              <div className="row" style={{ padding: '0px 5px 5px 5px' }}>
                {subjectList.map((item2, index2) => (
                  <div className="col-sm-2 col-md-2" style={{ padding: '0px 2px 5px 2px' }} key={index2}>
                    <div
                      className={selectedSubjectPosition === index2 ? "bg_class_select gravity_center" : "bg_white_outline_grey gravity_center"}
                      onClick={() => setSelectedSubjectPosition(index2)}
                      style={{ cursor: 'pointer' }}>
                      <p style={{ fontSize: 18 }}>{item2.subject_name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Route>
    </Router>
  );
};

export default ExamAddMarkSubjectWise;
